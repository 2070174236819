.prescriberModal {
    .ant-modal-header {
        padding-bottom: 2rem;
        border-bottom: 1px solid #cecece;

        .ant-modal-title {
            font-size: 1.5rem;
        }
    }

    .ant-modal-body {
        padding-top: 1rem;

        label {
            font-weight: 700;
        }

        .actionWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            button {
                background: #b4a099;
                border: 1px solid #b4a099;
                color: #fff;
            }
        }
    }
}

.order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input_order_search {
        width: auto !important;
    }
}

.customOrderTable {
    tbody {
        tr {
            td {
                .approve-button {
                    background: #b4a099;
                    border: 1px solid #b4a099;
                    color: #fff;
                    padding: 0.5rem;
                    border-radius: 8px;
                    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
                        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                    width: 100px;

                    &:hover {
                        background: white;
                        color: #b4a099;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    }
                }

                .deny-button {
                    background: rgb(77, 44, 44);
                    border: 1px solid #b4a099;
                    color: #FFF;
                    padding: 0.5rem;
                    border-radius: 8px;
                    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                    width: 100px;
                    margin-left: 1rem;

                    &:hover {
                        background: #fff;
                        color: rgb(77, 44, 44);
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    }
                }

                svg {
                    font-size: 1.5rem;

                    &:hover {
                        color: black !important;
                    }
                }
            }
        }
    }
}

.productTable {
    tbody {
        tr {
            td {
                svg {
                    font-size: 1.5rem;

                    &:hover {
                        color: black !important;
                    }
                }
            }
        }
    }
}

.topSection {
    .rightSection {
        .formItem {
            height: 100%;

            .input_patient_search {
                height: 48px;
            }
        }
    }
}

.pending-order-tab {
    .ant-tabs-nav {
        &::before {
            all: unset !important;
        }

        .ant-tabs-nav-wrap {
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-tabs-nav-list {
                background: #b4a099;
                padding: 10px 16px;
                border-radius: 50px;

                .ant-tabs-ink-bar-animated {
                    display: none;
                }

                .ant-tabs-tab {
                    padding: 0;

                    .ant-tabs-tab-btn {
                        color: rgb(77, 44, 44);
                        font-weight: 500;
                        font-size: 16px;
                        border: 1px solid #ad9289;
                        border-radius: 30px;
                        padding: 6px 10px;
                        transition: 0.5s all;

                        &:hover {
                            color: #fff;
                            border-color: #fff;
                            transition: 0.5s all;
                        }
                    }
                }

                .ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        font-size: 16px;
                        color: #FFF;
                        font-weight: 500;
                        border: 1px solid #fff;
                        border-radius: 30px;
                        padding: 6px 10px;
                    }
                }
            }
        }
    }
}

.action-button {
    display: flex;
    align-items: center;
}

.registered-user-table {
    .ant-table-tbody {
        .ant-table-cell {
            .view-button {
                background: #b4a099;
                border: 1px solid #b4a099;
                color: #fff;
                padding: 0.5rem;
                border-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
                    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                width: 100px;

                &:hover {
                    background: white;
                    color: #b4a099;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                }
            }
        }
    }
}

.patient-filter-head,
.order-filter-head {
    width: 100%;
    color: #54504a;
    font-size: 18px;
    font-weight: 600;
}

.selected-patients-text {
    width: 100%;
    color: #54504a;
    font-size: 18px;
    font-weight: 600;
}

.formGroup {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #D9D9D9;

    label {
        display: block;
        font-size: 0.75rem;
        font-weight: 600;
    }
}

.total-amount-container {
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    padding: 1rem 0rem;
    margin: 1rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .total-amount-heading {
        text-align: center;
        font-size: 20px;
        line-height: 150%;
        font-weight: 600;
    }

    .total-amount-price {
        text-align: center;
        font-size: 18px;
        line-height: 150%;
        font-weight: 600;
    }
}

.adding-margin {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.resetFiltersWrapper {
    height: 100%;

    button {
        padding: 1rem;
        background: #84726b;
        border: 1px solid #84726b;
        color: #FFF;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

        &:hover {
            opacity: 0.7;
            color: #000 !important;
            background-color: rgb(180, 160, 153) !important;
            border-color: rgb(180, 160, 153) !important;
        }
    }
}