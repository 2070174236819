* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $primaryFont !important;
}

html,
body {
    font-family: $primaryFont;
}

::placeholder {
    color: #a9a9a9;
}

.w-10 {
    max-width: 10% !important;
    width: 100%;
}

.w-20 {
    max-width: 20% !important;
    width: 100%;
}

.w-30 {
    max-width: 30% !important;
    width: 100%;
}

.w-40 {
    max-width: 40% !important;
    width: 100%;
}

.w-50 {
    max-width: 50% !important;
    width: 100%;
}

.w-60 {
    max-width: 60% !important;
    width: 100%;
}

.w-70 {
    max-width: 70% !important;
    width: 100%;
}

.w-80 {
    max-width: 80% !important;
    width: 100%;
}

.w-90 {
    max-width: 80% !important;
    width: 100%;
}

.contentWrapper {
    padding: 1rem;
    background: $white;
    margin-left: 300px;

    @media only screen and (max-width: 991px) {
        margin-left: auto;
        padding: 0;
    }
}

.ant-spin-dot-item {
    background: $primary !important
}

.swal2-confirm {
    background-color: $primary;
    padding: 0.75rem 1rem;
}

.ant-table-column-has-sorters {
    &.ant-table-column-sort {
        background: $primary !important;
    }

    &:hover {
        background: $primary !important;
        opacity: 0.9;
    }
}

.ant-table-column-sort {
    background: transparent !important;

    &.ant-table-cell-row-hover {
        background: #FAFAFA !important;
        cursor: pointer;
    }
}


.ant-descriptions {
    padding: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 0.625rem;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.05);

    .ant-descriptions-header {
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    .ant-descriptions-item {
        padding-bottom: 0.5rem;
    }

    .ant-descriptions-item-label {
        font-weight: 600;
    }

    .ant-descriptions-item-content {
        font-weight: 600;
    }
}

.formWrapper {
    margin-top: 2rem;

    .add-product-date-picker {
        width: 100%;
        height: 50px;
    }

    .ant-form {
        label {
            color: #54504a;
            font-size: 12px;
            width: 100%;
            font-weight: 500;

            &::before {
                display: none !important;
            }
        }

        .formSelect {
            height: 100%;

            .ant-select-selector {
                border-radius: 5px;
                border: 1px solid #dddddd;
                padding: 0.5rem 1rem;
            }
        }

        .formControl {
            border-radius: 5px;
            border: 1px solid #dddddd;
            padding: 0.75rem 1rem;
        }

        .forgotLink {
            font-weight: 600;
            color: $black;
            letter-spacing: 1px;
            text-decoration: none;
            text-transform: uppercase;
        }

        .w-100 {
            .ant-upload-select {
                width: 100%;
            }
        }
    }

    .orText {
        font-weight: 600;
        color: $black;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
    }
}

.formSelect {
    height: 100%;

    .ant-select-selector {
        border-radius: 5px;
        border: 1px solid #dddddd !important;
        padding: 0.5rem 1rem !important;
    }
}

.formControl {
    border-radius: 5px;
    border: 1px solid #dddddd;
    padding: 0.75rem 1rem;
}

.addCol {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;

    .ant-form-item {
        &:nth-child(1) {
            flex: 1;
        }
    }
}

.copywriteFooter {
    font-weight: 500;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1rem 0;
    text-align: center;
}

.footerLink {
    text-decoration: none;
    font-weight: 500;
}

.orderWrapper {

    .create-order-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 1rem;

        .craete-order-button {
            background: #b4a099;
            border: 1px solid #b4a099;
            color: #FFF;
            padding: 0.5rem;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        }
    }

    .settingtopSection {
        p {
            color: #54504a;
            font-size: 0.875rem;
            font-weight: 600;
        }

        input {
            color: #54504a;
            font-size: 0.875rem;
            font-weight: 600;
        }
    }

    .topSection {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .rightSection {
            display: flex;
            align-items: center;
            gap: 1rem;

            .last-order-date {
                height: 48px;
                width: 200px;
            }

            .filterBtn {
                background: $primary;
                border: 1px solid $primary;
                color: $white;
            }
        }
    }

    .filterSection {
        margin-bottom: 2rem;
        border: 0;
        border-radius: 0.625rem;
        overflow: hidden;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.05);

        .card-title {
            font-size: 1.13rem;
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    .tableCard {
        border: 0;
        border-radius: 0.625rem;
        overflow: hidden;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.05);

        .card-header {
            background: $white;
            padding: 1rem;
            text-align: end;
        }
    }
}

.bottomContentRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 0;
    }
}

.titleRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    input {
        @media only screen and (max-width: 767px) {
            width: 100% !important;
            margin-bottom: 1rem;
            border-radius: 5px;
            border: 1px solid #dddddd;
            padding: 0.75rem 1rem !important;
            margin-right: 0;
        }
    }

    @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
    }

    .titleRowInput {
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .buttonWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 767px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }

    .product-detail-head-wrapper {
        width: 100%;
    }

    .rightWrapper {
        @media only screen and (max-width: 767px) {
            width: 100%;
            height: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .ant-select {
            @media only screen and (max-width: 767px) {
                height: 45px;
            }
        }

        input {
            @media only screen and (max-width: 767px) {
                width: 100% !important;
                margin-bottom: 1rem;
                border-radius: 5px;
                border: 1px solid #dddddd;
                padding: 0.75rem 1rem !important;
                margin-right: 0 !important;
            }
        }

        button {
            @media only screen and (max-width: 767px) {
                margin-left: 0 !important;
            }
        }

        .ant-select-selector {
            @media only screen and (max-width: 767px) {
                width: 100% !important;
                margin-bottom: 1rem;
                border-radius: 5px;
                border: 1px solid #dddddd;
                padding: 0.75rem 1rem !important;
                margin-right: 0 !important;
            }
        }

        &>div {
            @media only screen and (max-width: 767px) {
                width: 100% !important;
            }

            &:last-child {
                margin-bottom: 0;

                input {
                    margin-bottom: 0;
                }
            }

            input {
                @media only screen and (max-width: 767px) {
                    width: 100% !important;
                    margin-bottom: 1rem;
                    border-radius: 5px;
                    border: 1px solid #dddddd;
                    padding: 0.75rem 1rem !important;
                    margin-right: 0;
                }
            }
        }

        .titleRowInput {
            @media only screen and (max-width: 767px) {
                width: 100%;
            }

        }

        .backBtn {
            @media only screen and (max-width: 767px) {
                margin-right: 0;
            }
        }
    }
}

.spinnerWrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.actionButton {
    display: flex;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .col-50 {
        width: 50%;
        float: left;
    }
}

.category-select {
    @media only screen and (max-width: 767px) {
        margin-right: 0 !important;
        margin-bottom: 0.875rem;
    }
}


.dateRangeWrapper {
    .ant-picker {
        padding: 0.8rem;
    }
}

.customProductSearch {
    margin-right: 1rem;
}

.customHomeSearchPadding {
    padding: 0.66rem;
}
.inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .customProductSearch{
        //  padding: 0 !important;
    }

    .inventory-search{
        .ant-input{
            border: none !important;
        }
    }
}

.dynamicLabel {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #54504a;
}

