.productCatalogWrapper {

    .rightWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        div {
            input {
                background: #ffffff;
                border-radius: 5px;
                border: 1px solid #dddddd !important;
                padding: 0.5rem 1rem !important;

            }

            div {
                z-index: 99;
            }
        }

        .category-select {
            width: auto;
            min-width: 300px;

            .ant-select-arrow {
                z-index: 99;
            }
        }

        .ant-select-selection-overflow {
            height: 100%;

            .ant-select-selection-search {
                width: 100%;
                border: none !important;

                input {
                    border: none !important;
                    height: 100%;
                    width: 100%;
                    padding: 0 !important;
                }
            }

            // div{
            //     // position: absolute !important;
            //     z-index: 99;
            //     height: auto;
            //     background: #ffffff;
            //     div{
            //        position: relative !important;
            //        height: auto !important;
            //     }
            // }
        }

        .wrapperItem {
            padding: 0.5rem 1rem;
            font-size: 0.875rem;
            font-weight: 500;
            cursor: pointer;

            &:hover {
                background: $primary;
                color: $white;
            }
        }

        input {
            margin-right: 1rem;
            height: 100%;
            padding: 0.7rem;
        }

        .buttonWrap {
            height: 100%;

            // button {
            //     height: 100%;
            // }
        }
    }
}

.campaignWrapper {
    .titleBar {
        align-items: center;

        h4 {
            margin-bottom: 0;
        }

        .ant-select {
            height: 40px !important;
        }
    }
}

.product-search{
    .customProductSearch{
        border: 1px solid #dddddd !important;
        .ant-input{
           border: none !important;
        }
    }
}