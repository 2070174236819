.sidebar {
    all: unset;
    position: fixed !important;
    width: 300px;
    max-width: 300px;
    width: 100%;
    height: 100vh;
    background: #FFF !important;
    border-right: 1px solid #e0e0e0 !important;
    z-index: 999;

    .logoWrap {
        margin: 1rem 1rem 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid #e0e0e0;

        img {
            width: 150px;
        }
    }

    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
    }

    .menuWrap {
        padding: 0.75rem;

        .ant-menu {
            list-style: none;
            padding-left: 0;
            border-inline-end: none;

            @media only screen and (max-width: 1024px) {
                overflow: hidden;
                overflow-y: auto;
                height: 80vh;
            }


            .ant-menu-item {
                font-size: 0.875rem;
                font-weight: 600;
                color: rgb(84, 80, 74);
                text-decoration: none;
                padding: 0.5rem 0.75rem;
                margin-bottom: 0px;
                // min-height: 48px;
                display: flex;
                align-items: center;
                background: transparent;
                border-radius: 8px;
                // transition: all 0.2s ease-in-out;

                .ant-menu-title-content {
                    span {
                        width: 100%;
                        display: flex;
                    }

                }

                &:hover {
                    background: rgb(245, 245, 245);
                }

                &.ant-menu-item-selected {
                    background: #b4a099;
                    border-radius: 8px;
                    padding: 0.75rem 0.75rem;
                    color: #fff;
                }

                svg {
                    font-size: 1.225rem;
                }

                a {
                    text-decoration: none;
                    transition: none;
                }
            }

            .ant-menu-submenu {

                .ant-menu-sub {
                    height: auto;
                }

                .ant-menu-submenu-title {
                    svg {
                        font-size: 1.225rem;
                        display: inline-flex;
                        align-items: center;
                        color: rgb(84 80 74);
                        font-style: normal;
                        line-height: 0;
                        text-align: center;
                        text-transform: none;
                        vertical-align: -0.125em;
                        text-rendering: optimizeLegibility;
                        -webkit-font-smoothing: antialiased;
                    }

                    .ant-menu-title-content {
                        font-size: 0.875rem;
                        font-weight: 600;
                        color: rgb(84, 80, 74);
                    }

                    .ant-menu-submenu-arrow {
                        color: rgb(84, 80, 74) !important;
                    }
                }
            }
        }
    }

    .bottomSection {
        margin: 0 1.5rem 1.5rem;

        .signInWrap {
            padding: 1rem;
            border: 1px solid rgb(222, 222, 222);
            border-radius: 4px;

            .mainText {
                font-size: 12px;
                color: rgb(51, 51, 51);
                font-weight: 500;
                margin-bottom: 4px;
            }

            .subText {
                font-size: 10px;
                color: rgb(132, 132, 132);
                font-weight: 400;
                margin-bottom: 10px;
            }

            .sampleBtn {
                color: rgb(0, 0, 0);
                font-weight: 500;
                font-size: 12px;
                padding: 8px;
                border-radius: 4px;
                border-color: rgb(0, 0, 0);
                border-width: 1px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;

                svg {
                    font-size: 1rem;
                }

                &:hover {
                    background-color: rgb(245, 245, 245);
                }
            }
        }
    }
}

.menuWrap {
    svg {
        font-size: 1.3rem !important;
    }
}