// Dashboard Style
.dashboardItem {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background-color: #fff;
    border-radius: 12px;
    backdrop-filter: blur(2px);
    margin-bottom: 30px;
    padding: 20px;
    position: relative;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(1.02);
    }
    
    .dashIcon {
        width: 60px;
        height: 60px;
        font-size: 1.75rem;
        color: #54504a;
        background: $white;
        border: 2px solid rgba(33, 31, 29, 0.3);
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    }

    .leftSection {
        h5 {
            font-size: 0.875rem;
            color: $black;
            font-weight: 600;
        }
    }

    .dashboardContent {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 2.375rem;
            color: #54504a;
            font-weight: 700;
        }
    }
}

.dashboardCard {
    height: 100%;
    border: 0;
    background: $white;
    border-radius: 12px;
    backdrop-filter: blur(2px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .card-header {
        background: $white;
        padding: 1rem;

        .card-title {
            font-size: 1rem;
            color: $black;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
}

.inventoryModal {
    .ant-modal-content {
        padding: 0;
        border-radius: 1rem;
        overflow: hidden;
 
        .ant-modal-header {
            padding: 1rem;
            background: $primary;
 
            .ant-modal-title {
                color: $white;
            }
        }
 
        .ant-modal-close {
            background: $white;
            border-radius: 50px;
        }
 
        .ant-modal-body {
            padding: 1rem;
            text-align: center;
 
            .inventoryIcon {
                svg {
                    font-size: 8rem;
                }
            }
 
            p {
                font-size: 1.5rem;
                font-weight: 600;
                color: $black;
            }
 
            .modalFooter {
                padding-top: 1rem;
                border-top: 1px solid $primary;
                display: flex;
                justify-content: center;
                gap: 1rem;
            }
        }
 
        .ant-modal-footer {
            display: none;
        }
    }
}
