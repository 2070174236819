.settingWrapper{
    .titleBar{
        display: flex;
        justify-content: space-between;
        align-items: center;

        button{
            background: #84726b;
            border: 1px solid #84726b;
            color: $white;
            padding: 0.5rem;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        
            &:hover {
                opacity: 0.7;
    color: $black !important;
    background-color: rgb(180, 160, 153) !important;
    border-color:rgb(180, 160, 153) !important ;
            }
        }
    }

    .shippingDetailsForm{
        input{
            padding: 10px;
        }

        .actionWrapper{
            .ant-form-item-control-input-content{
                display: flex;
                justify-content: center;
                align-items: center;
                button{
                    background: #b4a099;
                border: 1px solid #b4a099;
                color: #FFF;
                padding: 0.5rem;
                font-weight: 500;
                border-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                width: auto !important;
            
                &:hover {
                    background: white !important;
                    color: #b4a099 !important;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
                    border: 1px solid #b4a099 !important;
                }
                }
            }
           
        }
    }
    
}