// My account Style

.wrapper {
    .titleBar {
        margin: 1rem 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgb(222, 222, 222);

        h4 {
            color: #84726b;
            font-size: 24px;
            font-weight: 700;

            @media only screen and (max-width: 767px) {
                font-size: 1rem;
            }
        }

        .search-select-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;

            input {
                height: 75%;
                margin-right: 1rem;
            }
        }

        .search-invite-wrapper {
            input {
                height: 100%;
            }

            button {
                margin-left: 1rem;
                background: #84726b;
                border: 1px solid #b4a099;
                color: #FFF;
                padding: 0.4rem;
                border-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

                &:hover {
                    opacity: 0.7;
    color: $black !important;
    background-color: rgb(180, 160, 153) !important;
    border-color:rgb(180, 160, 153) !important ;
                }
            }
        }
    }

    .dltAccountWrapper {
        .accountCard {
            border: 0;
            border-radius: 0.625rem;
            box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.05);

            @media only screen and (max-width: 767px) {
                margin-bottom: 2rem;
            }

            .innerBody {
                display: flex;
                gap: 20px;
                padding: 1rem;

                @media only screen and (max-width: 767px) {
                    flex-direction: column;
                }

                .profileImageSec {
                    flex: 0 0 20%;
                    width: 100%;

                    @media only screen and (max-width: 767px) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }

                    .imgWrapper {
                        margin: 0 auto;

                        @media only screen and (max-width: 767px) {
                            width: 35%;
                        }
                    }
                }

                .formWrapper {
                    flex: 1;

                    .ant-upload-select {
                        width: 100%;
                    }
                }
            }
        }

        .dltCard {
            background: #00a8981a;
            border: 0;
            border-radius: 12px;
            text-align: center;
            box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.05);

            .card-body {
                padding: 1.5rem;
            }

            .cardTitle {
                color: rgb(132, 114, 107);
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
    }
}