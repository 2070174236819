.customPaginationTable {
    .ant-spin-container {
        position: relative;

        .ant-pagination {
            position: absolute;
            right: 10px;
            bottom: -2px;

            @media screen and (max-width:765px) {
                position: relative;
            }
        }
    }
}