// Global Variables

// Colors
$primary: #b4a099;
$white: #FFF;
$black: #000;
$secondary: #84726B;
$sucess: #198754;
$warning: #ffc107; 
$info: #0dcaf0;
$danger: #dc3545;
$light: #f8f9fa;

// Fonts
$primaryFont: "Montserrat", sans-serif;