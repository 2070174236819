// Homepage Style
.tableWrapper {
    .productTable {
        table {
            thead {
                tr {
                    th {

                        background-color: $primary;
                        color: $white;
                        border-bottom: 0;
                        font-size: 0.875rem;

                        &:first-child {
                            // border-radius: 12px 0 0 12px;
                        }

                        &:last-child {
                            // border-radius: 0 0 12px;
                        }

                        &::before {
                            display: none;
                        }
                    }
                }
            }

            tbody {
                tr {
                    cursor: pointer;

                    td {
                        font-weight: 500;
                        color: $black;
                    }
                }
            }
        }
    }

    .imgBox {
        width: 50px;
        height: 50px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .prouct-image-text {
            font-size: 8px;
            font-weight: 500;
        }
    }
}

.homesearchInput {
    width: 12vw;
}
.customPadding {
    padding: 0.7rem !important;
}