// Auth Screens Style

.authWrapper {
    width: 100%;
    height: 100vh;
    position: relative;
    background: $primary;
    font-family: "Montserrat", sans-serif;

    &.signUpWrap {
        @media only screen and (max-width: 767px) {
            height: 100%;
            padding: 2rem 0;
        }
    }

    .innerWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cardTitle {
        color: $white;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .authCard {
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 16px;

        .card-body {
            padding: 1.5rem 5rem;

            @media only screen and (max-width: 767px) {
                padding: 1.5rem;
            }
        }

        .title {
            color: #b4a099;
            font-size: 22px;
            font-weight: 700;
            text-align: center;
            width: 100%;
        }

        .subText {
            color: #777777;
            font-size: 10px;
            text-align: center;
        }
    }
}