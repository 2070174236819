// Auth Button Style

.authBtn {
    // all: unset;
    border: 0px solid #84726b !important;
    border-radius: 8px;
    background-color: #84726b !important;
    padding: 0.5rem;
    color: $white !important;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    height: 100%;

    &:hover {
        background-color: #b4a099 !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
        color: $black !important;
    }
}

.signOutBtn {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 600;
    color: rgb(0, 0, 0);
    letter-spacing: 1px;
    text-transform: uppercase;
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
    border-radius: 8px;

    &:hover {
        background-color: #b4a099 !important;
    }
}

.deleteAcBtn {
    width: 100%;
    font-size: 0.75rem;
    font-weight: 600;
    color: rgb(0, 0, 0);
    letter-spacing: 1px;
    text-transform: uppercase;
    border-width: 1px;
    border-radius: 8px;

    &:hover {
        border-color: rgba(0, 0, 0, 0.23);
        background-color: #b4a099 !important;
    }
}

.backBtn {
    font-size: 0.75rem;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-right: 1rem;
    background-color: #84726b !important;
    border-radius: 8px;
    border: 0px solid #84726b !important;
    padding: 0.5rem;
    color: $white !important;

    svg {
        font-size: 18px;
    }

    &:hover {
        opacity: 0.7;
        color: $black !important;
        background-color: rgb(180, 160, 153) !important;
        border-color: rgb(180, 160, 153) !important;
    }
}

.homeBtn {
    font-size: 0.75rem;
    font-weight: 600;
    color: #757575;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-width: 1px;
    border-radius: 8px;

    svg {
        font-size: 18px;
    }

    &:hover {
        color: #000;
    }
}

.submitBtn {
    all: unset;
    display: block;
    width: 100%;
    font-size: 0.75rem;
    font-weight: 600;
    color: $white !important;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #84726b !important;
    border-width: 1px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 8px !important;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        color: #000 !important;
    }
}

.updateBtn {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    background-color: #84726b;
    border-radius: 8px;
    height: 36px;
    width: 120px !important;
    color: $white;

    svg {
        font-size: 1.5rem;
    }

    &:hover {
        opacity: 0.7;
        color: $black !important;
        background-color: rgb(180, 160, 153) !important;
    }
}

.updateDetailsBtn {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    background-color: #84726b;
    border-radius: 8px;
    height: 36px;
    color: $white;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;

    svg {
        font-size: 1.375rem;
    }

    &:hover {
        opacity: 0.7;
        color: $black !important;
        background-color: rgb(180, 160, 153) !important;
    }
}

.listingButton {
    background: #84726b;
    border: 1px solid #84726b;
    color: #FFF;
    padding: 0.5rem;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    &:hover {
        opacity: 0.7;
        color: $black !important;
        background-color: rgb(180, 160, 153) !important;
        border-color: rgb(180, 160, 153) !important;
    }
}

.proceedBtn {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    background-color: #84726b;
    border-radius: 8px;
    height: 36px;
    color: $white;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 1px;

    svg {
        font-size: 1.375rem;
    }

    &:hover {
        opacity: 0.7;
    }
}

.skipBtn {
    border-radius: 8px;
    height: 36px;
    color: $secondary;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 1px;

    svg {
        font-size: 1.375rem;
    }

    &:hover {
        opacity: 0.7;
    }
}

.craete-order-button {
    background: #84726b;
    border: 1px solid #84726b;
    color: #FFF;
    padding: 0.5rem;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    &:hover {
        opacity: 0.7;
        color: $black !important;
        background-color: rgb(180, 160, 153) !important;
        border-color: rgb(180, 160, 153) !important;
    }
}

.login-button {
    background-color: #84726b !important;
    border-radius: 8px;
    border: 0px solid #84726b !important;
    padding: 0.5rem;
    color: $white !important;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    height: 100%;

    &:hover {
        opacity: 0.7;
        color: $black !important;
        background-color: rgb(180, 160, 153) !important;
        border-color: rgb(180, 160, 153) !important;
    }
}