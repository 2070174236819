.tableWrapper {
    .registered-user-table {
        table {
            thead {
                tr {
                    th {

                        background-color: #b4a099;
                        color: #FFF;
                        border-bottom: 0;
                        font-size: 0.875rem;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    font-weight: 500;
                    color: #000;

                    svg {
                        font-size: 25px;
                    }

                    .action-button {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .activate-button {
                            margin-right: 1rem;
                            background: #b4a099;
                            border: 1px solid #b4a099;
                            color: #FFF;
                            padding: 0.5rem;
                            border-radius: 8px;
                            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                            width: 100px;

                            &:hover {
                                background: white;
                                color: #b4a099;
                                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                            }
                        }

                        .deactivate-button {
                            background: rgb(77, 44, 44);
                            border: 1px solid #b4a099;
                            color: #FFF;
                            padding: 0.5rem;
                            border-radius: 8px;
                            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
                            width: 100px;

                            &:hover {
                                background: #fff;
                                color: rgb(77, 44, 44);
                                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.invite-user-modal {
    .ant-modal-header {
        padding-bottom: 1rem;
        border-bottom: 1px solid #cecece;

        .ant-modal-title {
            font-size: 1.5rem;
        }
    }

    .ant-modal-body {
        padding-top: 1rem;

        label {
            font-weight: 700;
        }

        .actionWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            button {
                margin-left: 1rem;
                background: #84726b;
                border: 1px solid #b4a099;
                color: #FFF;
                padding: 0.4rem;
                border-radius: 8px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

                &:hover {
                    opacity: 0.7;
                    color: $black !important;
                    background-color: rgb(180, 160, 153) !important;
                    border-color: rgb(180, 160, 153) !important;
                }
            }
        }
    }
}