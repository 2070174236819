// Category Style
.categoryItem {
    background: $primary;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        transform: scale(1.02);
    }

    .categoryImage {
        width: 90px;
        height: 90px;
        background: $white;
        border-radius: 50px;
        margin: 0 auto 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

        img {
            width: 70%;
        }
    }

    .categoryName {

        h5 {
            font-size: 1.375rem;
            color: $white;
            font-weight: 600;
            margin-bottom: 0;
            text-align: center;
        }

        span {
            font-size: 0.75rem;
            color: #757575;
        }
    }
}

.categoryWrapper {

    .categoryItems {
        display: flex;
        align-items: center;
        gap: 15px;
        margin: 1rem 0 0;
        cursor: pointer;

        .categoryImage {
            width: 40px;
            height: 40px;
            background: #ccc;
            border-radius: 50PX;
        }

        .categoryName {
            border-bottom: 1px solid #ccc;
            width: 100%;
            height: 60px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            h5 {
                font-size: 0.875rem;
                color: #212121;
                font-weight: 400;
                margin-bottom: 0;
            }

            span {
                font-size: 0.75rem;
                color: #757575;
            }
        }
    }

    &.filterProductWrapper {
        align-items: flex-start;

        .categoryList {
            .categoryItems {
                .categoryImage {
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    background: none;
                    display: flex;
                    justify-content: center;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                    }
                }

                .categoryName {
                    border-bottom: 1px solid #ccc;
                    width: 100%;
                    height: 60px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;

                    h5 {
                        font-size: 0.875rem;
                        color: #212121;
                        font-weight: 400;
                        margin-bottom: 0;
                    }

                    span {
                        font-size: 0.75rem;
                        color: #757575;
                    }
                }
            }
        }
    }
}

.detailsWrapper {
    .detailBox {
        padding: 1rem;
        background: #F5F5F5;
        border-radius: 12px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h5 {
            color: #54504a;
            font-size: 0.875rem;
            font-weight: 700;
            text-transform: uppercase;
        }

        p {
            margin-bottom: 0;
        }
    }

    .productNameWrap {
        h3 {
            color: #84726b;
            font-size: 30px;
            font-weight: 700;
            text-align: left;

            @media only screen and (max-width: 767px) {
                font-size: 1.5rem;
                margin-top: 1rem;
            }
        }
    }

    .productImageWrap {
        height: 400px;
        display: flex;
        align-items: center;
        border: 1px solid #f5f5f5;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

        .product-image-text {
            margin-top: 0;
            margin-bottom: 1rem;
            font-size: 18px;
            line-height: 150%;
            font-weight: 600;
            text-align: center;
            width: 100%;
        }
    }
}

.logWrapper {
    height: 100%;
    padding: 2rem;
    background: #F5F5F5;

    .title {
        font-size: 1rem;
        font-weight: 600;
    }

    .topSection {
        padding-bottom: 3rem;
        border-bottom: 1px solid #F5F5F5;
    }

    .bottomSection {
        margin-top: 2rem;
    }

    .logList {
        list-style: none;
        padding-left: 0;

        li {
            margin-top: 0.5rem;
        }
    }
}

.audience-status-dropdown {
    width: 100%;
    min-width: 100px;
    .ant-select-selector {
        .ant-select-selection-wrap {
            .ant-select-selection-item {
                font-size: 12px;
                line-height: 150%;
                font-weight: 700;
            }
        }
    }

    .ant-select-arrow {
        .anticon {
            svg {
                font-size: 12px;
                color: $black;
            }
        }
    }
}

.radio-group-type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0.8rem;

    .ant-radio-wrapper-checked{
        .ant-radio{
            .ant-radio-inner{
                border-color: #b4a099;
                background-color: #b4a099;
            }
        }
    }
}